import React from 'react';
import { Link, StatusIndicator } from '../../../aws-ui-components';

export const COLUMN_DEFINITIONS = [
  {
    id: 'id',
    header: 'Job ID',
    cell: item => (
      <Link
        href={
          'https://console.aws.amazon.com/glue/home?region=us-east-1#jobRun:jobName=amazon-cloak;jobRunId=' + item.Id
        }
        target="_blank"
      >
        {item.Id}
      </Link>
    ),
    maxWidth: '120px',
    sortingField: 'Id'
  },
  {
    id: 'JobRunState',
    header: 'Job State',
    cell: item => <StatusIndicator type={getStatus(item.JobRunState)}> {item.JobRunState}</StatusIndicator>,
    minWidth: '100px',
    sortingField: 'JobRunState'
  },
  {
    id: 'StartedOn',
    header: 'Started On',
    cell: item =>
      item.StartedOn.toISOString()
        .slice(0, 19)
        .replace(/Z/g, ' ')
        .replace(/T/g, ' '),
    minWidth: '100px',
    sortingField: 'StartedOn'
  },
  {
    id: 'ExecutionTime',
    header: 'Execution Time',
    cell: item => item.ExecutionTime + ' seconds',
    minWidth: '100px',
    sortingField: 'ExecutionTime'
  },
  {
    id: 'NumberOfWorkers',
    header: 'DPUs',
    cell: item => item.NumberOfWorkers,
    minWidth: '100px',
    sortingField: 'NumberOfWorkers'
  },
  {
    id: 'CloakColumns',
    header: 'Cloak Columns',
    cell: item => parseColumns(item.Arguments['--CLOAK_CONFIG'] ? item.Arguments['--CLOAK_CONFIG'] : null),
    minWidth: '100px',
    sortingField: 'CloakColumns'
  },
  {
    id: 'CloakOperation',
    header: 'Cloak Operation',
    cell: item => parseOperation(item.Arguments['--CLOAK_CONFIG'] ? item.Arguments['--CLOAK_CONFIG'] : null),
    minWidth: '100px',
    sortingField: 'CloakOperation'
  },
  {
    id: 'SourceData',
    header: 'Source Data Store',
    cell: item => parseDataStore('source', item.Arguments['--CLOAK_CONFIG'] ? item.Arguments['--CLOAK_CONFIG'] : null),
    minWidth: '100px',
    sortingField: 'SourceData'
  },
  {
    id: 'TargetData',
    header: 'Target Data Sink',
    cell: item => parseDataStore('target', item.Arguments['--CLOAK_CONFIG'] ? item.Arguments['--CLOAK_CONFIG'] : null),
    minWidth: '100px',
    sortingField: 'TargetData'
  }
];

const getStatus = status => {
  if (status === 'SUCCEEDED') {
    return 'success';
  } else if (status === 'FAILED' || status === 'ERROR' || status === 'TIMEOUT') {
    return 'error';
  } else {
    return 'in-progress';
  }
};

const parseColumns = cloakConfig => {
  if (!cloakConfig) {
    return '';
  }
  try {
    let config = JSON.parse(cloakConfig);
    if (config['cloak'] && config['cloak']['fields']) {
      return config['cloak']['fields'].map(field => field.fieldName).join(', ');
    }
  } catch (e) {
    return '';
  }
};

const parseOperation = cloakConfig => {
  if (!cloakConfig) {
    return '';
  }
  try {
    let config = JSON.parse(cloakConfig);
    if (config['cloak'] && config['cloak']['operation']) {
      return config['cloak']['operation'];
    }
  } catch (e) {
    return '';
  }
};

const parseDataStore = (sourceTarget, cloakConfig) => {
  if (!cloakConfig) {
    return '';
  }
  try {
    let config = JSON.parse(cloakConfig);
    if (
      config[sourceTarget] &&
      config[sourceTarget]['glueTable'] &&
      (config[sourceTarget]['glueTable']['name_space'] && config[sourceTarget]['glueTable']['table_name'])
    ) {
      return (
        config[sourceTarget]['glueTable']['name_space'] +
        '.' +
        config[sourceTarget]['glueTable']['table_name'] +
        ' (AWS Glue)'
      );
    } else {
      if (sourceTarget === 'source') {
        return config['source']['sourceOptions']['connection_type'] + ' (Manual)';
      } else if (sourceTarget === 'target') {
        return config['target']['targetOptions']['connection_type'] + ' (Manual)';
      }
    }
  } catch (e) {
    return '';
  }
};

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Tokenization Job Properties',
    options: [{ id: 'id', label: 'Job ID', editable: false }, { id: 'state', label: 'State', editable: true }]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Jobs' },
  { value: 30, label: '30 Jobs' },
  { value: 50, label: '50 Jobs' }
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: [
    'id',
    'JobRunState',
    'StartedOn',
    'NumberOfWorkers',
    'ExecutionTime',
    'CloakColumns',
    'CloakOperation',
    'SourceData',
    'TargetData'
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
