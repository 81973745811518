import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import { Route } from 'react-router-dom';

import '@amzn/awsui-global-styles/polaris.css';

import awsconfig from '../aws-exports';
import ServiceHomepage from './pages/homepage/ServiceHomepage.jsx';
import CreateJobForm from './pages/createjob/CreateJobForm.jsx';
import JobTable from './pages/jobs';

Amplify.configure(awsconfig);

export default withAuthenticator(
  class App extends React.Component {
    render() {
      return (
        <div>
          <Route exact path="/" component={ServiceHomepage} />
          <Route path="/create" component={CreateJobForm} />
          <Route path="/jobs" component={JobTable} />
        </div>
      );
    }
  }
);
