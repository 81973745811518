import React from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation } from '../aws-ui-components';

function ServiceNavigation(props) {
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href.substring(1));
    }
  }

  return (
    <SideNavigation
      header={{ text: 'Amazon Cloak', href: '#/' }}
      items={items}
      activeHref={`#${props.location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

const items = [
  {
    type: 'section',
    text: 'Tokenization',
    items: [{ type: 'link', text: 'Data Pipeline Jobs', href: '#/jobs' }]
  },
  {
    type: 'section',
    text: 'Integrations',
    items: [{ type: 'link', text: 'Amazon Macie', href: 'https://amazon.com' }]
  }
];

export default withRouter(ServiceNavigation);
