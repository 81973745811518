import React, { useState, useEffect } from 'react';
import DataProvider from './data-provider';
import ServiceNavigation from '../../ServiceNavigation.jsx';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES
} from './table-config.jsx';
import {
  AppLayout,
  Header,
  SpaceBetween,
  Box,
  BreadcrumbGroup,
  Button,
  Flashbar,
  CollectionPreferences,
  Pagination,
  TextFilter,
  Table,
  FormField,
  RadioGroup,
  HelpPanel,
  Icon
} from '../../../aws-ui-components';

import { useCollection } from '@awsui/collection-hooks';

export default () => {
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      notifications={<FlashMessage />}
      breadcrumbs={<Breadcrumbs />}
      content={<DetailsTable />}
      contentType="table"
      tools={Tools}
    />
  );
};

const DetailsTable = () => {
  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [distributions, setDistributions] = useState([]);
  const [selectedDistributions, setSelectedDistributions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    distributions,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        )
      }
    }
  );

  // fetch distributions after render of the component
  useEffect(() => {
    new DataProvider().getData('JobList', distributions => {
      setDistributions(distributions);
      setLoading(false);
    });
  }, []);

  // Keeps track of how many distributions are selected
  function headerCounter(selectedDistributions, distributions) {
    return selectedDistributions.length
      ? `(${selectedDistributions.length} of ${distributions.length})`
      : `(${distributions.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      header={
        <TableHeader
          selectedDistributions={selectedDistributions}
          counter={headerCounter(selectedDistributions, distributions)}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: PAGE_SELECTOR_OPTIONS
          }}
          visibleContentPreference={{
            title: 'Select visible columns',
            options: CONTENT_SELECTOR_OPTIONS
          }}
          wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
          }}
          customPreference={(value, setValue) => (
            <FormField stretch={true} label="View as">
              <RadioGroup
                value={value}
                onChange={({ detail }) => setValue(detail.value)}
                items={CUSTOM_PREFERENCE_OPTIONS}
              />
            </FormField>
          )}
        />
      }
      wrapLines={preferences.wrapLines}
      sortingColumn={COLUMN_DEFINITIONS[2]}
      selectedItems={selectedDistributions}
      onSelectionChange={({ detail }) => setSelectedDistributions(detail.selectedItems)}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={filterCounter(filteredItemsCount)}
          filteringPlaceholder="Search distributions"
        />
      }
    />
  );
};

// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ selectedDistributions, counter }) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  return (
    <Header
      variant="h2"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button href="#/create" variant="primary">
            Create Tokenization Job
          </Button>
        </SpaceBetween>
      }
    >
      Tokenization Jobs
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Amazon Cloak',
        href: '#/'
      },
      {
        text: 'Data Pipeline Jobs',
        href: '#/jobs'
      }
    ]}
  />
);

// Flash message content
const FlashMessage = () => {
  if (window.location.hash.includes('created=true')) {
    const [items, setItems] = useState([
      {
        key: 'notification',
        type: 'success',
        dismissible: true,
        onDismiss: () => setItems([]),
        content: 'Job successfully created'
      }
    ]);
    return <Flashbar items={items} />;
  } else if (window.location.hash.includes('error=true')) {
    const [items, setItems] = useState([
      {
        key: 'notification',
        type: 'error',
        dismissible: true,
        onDismiss: () => setItems([]),
        content: 'Job failed to create'
      }
    ]);
    return <Flashbar items={items} />;
  } else {
    const [items, setItems] = useState([]);
    return <Flashbar items={items} />;
  }
};

const Tools = [
  <HelpPanel key="help" header={<h2>Amazon Cloak</h2>}>
    <p>
      The Data Pipeline Jobs page allows you to see the status of your tokenization jobs on the Amazon Cloak service.
    </p>
  </HelpPanel>
];
