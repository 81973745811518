/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "amazon-cloak-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2dhk14wk9rprz.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:cfa31cd7-be4e-432a-8718-870a58ee1b16",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kUbe9Twm6",
    "aws_user_pools_web_client_id": "3v2ea6dk30okqic9qc73unuvb7",
    "oauth": {}
};


export default awsmobile;
