import { Auth } from 'aws-amplify';
const { GlueClient, GetJobRunsCommand } = require('@aws-sdk/client-glue');

export default class DataProvider {
  getData(_, callback) {
    Auth.currentCredentials().then(credentials => {
      console.log(credentials);
      const client = new GlueClient({
        credentials: Auth.essentialCredentials(credentials),
        region: 'us-east-1'
      });

      const command = new GetJobRunsCommand({
        JobName: 'amazon-cloak',
        MaxResults: 100
      });

      client.send(command).then(res => {
        console.log(res.JobRuns);
        callback(res.JobRuns);
      });
    });
  }
}
