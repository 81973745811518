import React, { useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
const { GlueClient, StartJobRunCommand, GetTablesCommand, GetDatabasesCommand } = require('@aws-sdk/client-glue');
import ServiceNavigation from '../../ServiceNavigation.jsx';
import { CLOAK_OPERATION, SOURCE_TYPE, TARGET_TYPE } from './form-config.jsx';
import {
  Alert,
  AppLayout,
  AttributeEditor,
  BreadcrumbGroup,
  Button,
  Checkbox,
  ColumnLayout,
  ExpandableSection,
  Form,
  FormField,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  Textarea,
  Tiles,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Icon
} from '../../../aws-ui-components';

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class CreateJobForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceGlueDBValues: [],
      sourceGlueTables: [],
      targetGlueDBValues: [],
      targetGlueTables: [],
      toolsIndex: 0
    };
  }

  componentDidMount() {
    this.getGlueDatabases();
    this.getGlueTables('source', '');
    this.getGlueTables('target', '');
  }

  getGlueDatabases = () => {
    Auth.currentCredentials().then(credentials => {
      const client = new GlueClient({
        credentials: Auth.essentialCredentials(credentials),
        region: 'us-east-1'
      });

      const command = new GetDatabasesCommand({
        MaxResults: 100,
        ResourceShareType: 'ALL'
      });

      client.send(command).then(res => {
        let dbList = res.DatabaseList;

        let dbListFiltered = dbList.map(item => {
          return {
            label: item.Name,
            value: item.Name
          };
        });

        this.setState({ sourceGlueDBValues: dbListFiltered, targetGlueDBValues: dbListFiltered });
      });
    });
  };

  getGlueTables = (sourceTarget, dbName) => {
    if (dbName === '') {
      if (sourceTarget == 'source') {
        this.setState({ sourceGlueTables: [{ label: 'Select a database', value: '#', disabled: true }] });
      } else {
        this.setState({ targetGlueTables: [{ label: 'Select a database', value: '#', disabled: true }] });
      }
    } else {
      Auth.currentCredentials().then(credentials => {
        const client = new GlueClient({
          credentials: Auth.essentialCredentials(credentials),
          region: 'us-east-1'
        });

        const command = new GetTablesCommand({
          DatabaseName: dbName,
          MaxResults: 100
        });

        client.send(command).then(res => {
          let tableList = res.TableList;

          let tableListFiltered = tableList.map(item => {
            return {
              label: item.Name,
              value: item.Name
            };
          });

          if (sourceTarget == 'source') {
            this.setState({ sourceGlueTables: tableListFiltered });
          } else {
            this.setState({ targetGlueTables: tableListFiltered });
          }
        });
      });
    }
  };

  render() {
    return (
      <AppLayout
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        navigationOpen={true}
        breadcrumbs={<Breadcrumbs />}
        content={
          <Content
            getGlueTables={this.getGlueTables}
            sourceGlueDBValues={this.state.sourceGlueDBValues}
            sourceGlueTables={this.state.sourceGlueTables}
            targetGlueDBValues={this.state.targetGlueDBValues}
            targetGlueTables={this.state.targetGlueTables}
            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
          />
        }
        contentType="form"
        tools={Tools[this.state.toolsIndex]}
        onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
        toolsOpen={this.state.toolsOpen}
      />
    );
  }
}

// The content in the main content area of the App layout
const Content = props => {
  // Number of fields
  const [numberOfFields, setNumberOfFields] = React.useState(2);

  // Job Type
  const [jobType, setJobType] = React.useState('deidentify');
  const [numWorkers, setNumWorkers] = React.useState('10');

  // Field Config
  const [fieldValue, setFieldValue] = React.useState('');

  // Source Data Schema
  const [sourceDataType, setSourceDataType] = React.useState('glue');

  // Source Glue Config
  const [sourceGlueDBValue, setSourceGlueDBValue] = React.useState([]);
  const [sourceGlueTable, setSourceGlueTable] = React.useState({
    label: '',
    value: ''
  });

  // Source Glue Options
  const [sourceConnectionType, setSourceConnectionType] = React.useState({
    label: 's3',
    value: 's3'
  });
  const [sourceS3Path, setSourceS3Path] = React.useState('');
  const [sourceS3Format, setSourceS3Format] = React.useState({
    label: '',
    value: ''
  });
  const [sourceDBURL, setSourceDBURL] = React.useState('');
  const [sourceDBuser, setSourceDBuser] = React.useState('');
  const [sourceDBpass, setSourceDBpass] = React.useState('');
  const [sourceDBtable, setSourceDBtable] = React.useState('');

  // Encryption configuration
  const [keyValue, setKeyValue] = React.useState('');
  const [tokenMethod, setTokenMethod] = React.useState({
    label: '',
    value: ''
  });
  const [fpeAlphabet, setFpeAlphabet] = React.useState({
    label: '',
    value: ''
  });

  // Target Data Schema
  const [targetDataType, setTargetDataType] = React.useState('glue');

  // Target Glue Config
  const [targetGlueDBValue, setTargetGlueDBValue] = React.useState({
    label: '',
    value: ''
  });
  const [targetGlueTable, setTargetGlueTable] = React.useState({
    label: '',
    value: ''
  });

  // Target Glue Options
  const [targetConnectionType, setTargetConnectionType] = React.useState({
    label: 's3',
    value: 's3'
  });
  const [targetS3Path, setTargetS3Path] = React.useState('');
  const [targetS3Format, setTargetS3Format] = React.useState({
    label: '',
    value: ''
  });
  const [targetDBURL, setTargetDBURL] = React.useState('');
  const [targetDBuser, setTargetDBuser] = React.useState('');
  const [targetDBpass, setTargetDBpass] = React.useState('');
  const [targetDBtable, setTargetDBtable] = React.useState('');

  // console.log(jobType, jobNameValue, jobDescrValue, fieldValue, keyValue.value, tokenMethod.value, sourceGlueDBValue.value, sourceGlueTable.value)

  const generateCloakConfig = () => {
    let cloakConfig = {};

    // Get Cloak operation
    cloakConfig['cloak'] = {
      operation: jobType
    };

    // Get source schema
    if (sourceDataType == 'glue') {
      cloakConfig['source'] = {
        glueTable: {
          name_space: sourceGlueDBValue.value,
          table_name: sourceGlueTable.value
        }
      };
    }

    if (sourceDataType == 'options') {
      if (sourceConnectionType.value == 's3') {
        cloakConfig['source'] = {
          sourceOptions: {
            connection_type: 's3',
            connection_options: {
              paths: [sourceS3Path]
            },
            format: sourceS3Format.value
          }
        };

        if (sourceS3Format == 'csv') {
          cloakConfig['source']['sourceOptions']['formatOptions'] = {
            withHeader: true
          };
        }
      } else {
        cloakConfig['source'] = {
          sourceOptions: {
            connection_type: sourceConnectionType.value,
            connection_options: {
              url: sourceDBURL,
              dbtable: sourceDBtable,
              user: sourceDBuser,
              password: sourceDBpass
            }
          }
        };
      }
    }

    // Get target schema
    if (targetDataType == 'glue') {
      cloakConfig['target'] = {
        glueTable: {
          name_space: targetGlueDBValue.value,
          table_name: targetGlueTable.value
        }
      };
    }

    if (targetDataType == 'options') {
      if (targetConnectionType.value == 's3') {
        cloakConfig['target'] = {
          targetOptions: {
            connection_type: 's3',
            connection_options: {
              path: targetS3Path
            },
            format: targetS3Format.value
          }
        };
      } else {
        cloakConfig['target'] = {
          targetOptions: {
            connection_type: targetConnectionType.value,
            connection_options: {
              url: targetDBURL,
              dbtable: targetDBtable,
              user: targetDBuser,
              password: targetDBpass
            }
          }
        };
      }
    }

    // Get fields
    cloakConfig['cloak']['fields'] = [
      {
        cryptoKey: {
          wrappedKey: keyValue
        },
        fieldName: fieldValue,
        method: tokenMethod.value
      }
    ];

    // Set partition size
    cloakConfig['cloak']['partitionSize'] = 1000;

    console.log(cloakConfig);

    return cloakConfig;
  };

  const runGlueJob = e => {
    Auth.currentCredentials().then(credentials => {
      const client = new GlueClient({
        credentials: Auth.essentialCredentials(credentials),
        region: 'us-east-1'
      });

      // TEMP HARDCODED CLOAK CONFIG
      // JSON.stringify(generateCloakConfig())

      /* eslint-disable */
      const cloakConfig =
        '{"cloak":{"fields":[{"cryptoKey":{"wrappedKey":"AQIDAHgyZn9pAEQUk8tHijKJNkikYjaOYtyJR1KX+Sp1KfeI8wGkR6PNjUYPMY9g9nJGSTgsAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMpKQs1zU4ZUvaJzgUAgEQgDt2xDIOX4x4KBv5+3wH6GCmL/zAE3lK9u1yW+waIKalovr/BfZCXKfhIldn4kFUPd0W2BehIL9E80fqFQ=="},"fieldName":"DriversLicence","method":"deterministic"},{"cryptoKey":{"wrappedKey":"AQIDAHgyZn9pAEQUk8tHijKJNkikYjaOYtyJR1KX+Sp1KfeI8wGkR6PNjUYPMY9g9nJGSTgsAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMpKQs1zU4ZUvaJzgUAgEQgDt2xDIOX4x4KBv5+3wH6GCmL/zAE3lK9u1yW+waIKalovr/BfZCXKfhIldn4kFUPd0W2BehIL9E80fqFQ=="},"fieldName":"SIN","method":"fpe","fpeConfig":{"alphabetType":"Numeric"}}],"operation":"deidentify","partitionSize":1000},"source":{"glueTable":{"name_space":"cloak-prod-db","table_name":"cloak_people_data"}},"target":{"glueTable":{"name_space":"cloak-dev-db","table_name":"dev_bank_data_tokenized"}}}';

      const command = new StartJobRunCommand({
        JobName: 'amazon-cloak',
        Arguments: {
          '--CLOAK_CONFIG': cloakConfig
        },
        WorkerType: 'G.1X',
        NumberOfWorkers: parseInt(numWorkers)
      });

      client
        .send(command)
        .then(response => {
          window.location.href = '/#/jobs?created=true';
        })
        .catch(e => {
          window.location.href = '/#/jobs?error=true';
        });
    });
  };

  const generateWrappedKey = async e => {
    const data = await fetch('https://api.cloak.samfred.people.aws.dev/api/cloak/Deidentify/key', {
      method: 'POST',
      body: JSON.stringify({
        cmkArn: '78317a3a-eba5-462d-9e98-2165d28732b4'
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  };

  return (
    <Form
      header={<Header variant="h1">Create Tokenization Job</Header>}
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
          <Button href="#/" variant="link">
            Cancel
          </Button>
          <Button variant="primary" onClick={runGlueJob} id="runjob">
            Run Tokenization Job
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        {/* Job Type */}
        <Container header={<Header>Job Info</Header>}>
          <FormField stretch={true}>
            <Tiles onChange={({ detail }) => setJobType(detail.value)} value={jobType} items={CLOAK_OPERATION} />
          </FormField>
          <div style={{ marginTop: '10px' }} />
          <FormField
            label="Number of Glue DPUs"
            description="Set the number of Glue DPUs needed for your tokenization job"
            stretch={true}
          >
            <Input onChange={({ detail }) => setNumWorkers(detail.value)} value={numWorkers} placeholder="" />
          </FormField>
        </Container>

        {/* Job Details */}
        <Container header={<Header variant="h2">Source Data Schema</Header>}>
          <FormField stretch={true}>
            <Tiles
              onChange={({ detail }) => setSourceDataType(detail.value)}
              value={sourceDataType}
              items={SOURCE_TYPE}
            />
          </FormField>
          <div style={{ marginTop: '10px' }} />
          <FormField stretch={true}>
            {sourceDataType == 'glue' ? (
              <ColumnLayout columns={3}>
                <FormField label="Database" description="Select the database within AWS Glue where your schema resides">
                  <Select
                    selectedOption={sourceGlueDBValue}
                    onChange={({ detail }) => {
                      setSourceGlueDBValue(detail.selectedOption);
                      props.getGlueTables('source', detail.selectedOption.value);
                    }}
                    options={props.sourceGlueDBValues}
                    placeholder="AWS glue database name"
                    filteringType="auto"
                  />
                </FormField>
                <FormField
                  label="Table"
                  description="Select the table within AWS Glue where your schema has been defined"
                >
                  <Select
                    selectedOption={sourceGlueTable}
                    onChange={({ detail }) => setSourceGlueTable(detail.selectedOption)}
                    options={props.sourceGlueTables}
                    placeholder="AWS Glue table name"
                    filteringType="auto"
                  />
                </FormField>
                <div className="custom-header">
                  <Button
                    href="https://console.aws.amazon.com/glue/home?region=us-east-1#addCrawler:"
                    iconAlign="right"
                    iconName="external"
                  >
                    Create Schema
                  </Button>
                </div>
              </ColumnLayout>
            ) : (
              <FormField stretch={true}>
                <FormField
                  label="Connection Type"
                  description="Select the type of connection for your source data store"
                >
                  <Select
                    selectedOption={sourceConnectionType}
                    onChange={({ detail }) => setSourceConnectionType(detail.selectedOption)}
                    options={[
                      { label: 's3', value: 's3' },
                      { label: 'mysql', value: 'mysql' },
                      { label: 'postgresql', value: 'postgresql' },
                      { label: 'redshift', value: 'redshift' },
                      { label: 'sqlserver', value: 'sqlserver' },
                      { label: 'oracle', value: 'oracle' }
                    ]}
                  />
                </FormField>
                <div style={{ marginTop: '10px' }} />
                <FormField stretch={true}>
                  {sourceConnectionType.value == 's3' && (
                    <FormField
                      label="S3 Connection Options"
                      description="Configure the below options if your connection type is from Amazon s3"
                    >
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="S3 Path" description="The path to your data source inside Amazon S3">
                        <Input
                          onChange={({ detail }) => setSourceS3Path(detail.value)}
                          value={sourceS3Path}
                          placeholder=""
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Data Format">
                        <Select
                          selectedOption={sourceS3Format}
                          onChange={({ detail }) => setSourceS3Format(detail.selectedOption)}
                          options={[
                            { label: 'avro', value: 'avro' },
                            { label: 'csv', value: 'csv' },
                            { label: 'json', value: 'json' },
                            { label: 'orc', value: 'orc' },
                            { label: 'parquet', value: 'parquet' },
                            { label: 'glueparquet', value: 'glueparquet' }
                          ]}
                        />
                      </FormField>
                    </FormField>
                  )}
                  {sourceConnectionType.value !== 's3' && (
                    <FormField
                      label="Database Connection Options"
                      description="Configure the below options if your connection type is a relational database or Amazon Redshift"
                    >
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="URL (in JDBC format)">
                        <Input
                          onChange={({ detail }) => setSourceDBURL(detail.value)}
                          value={sourceDBURL}
                          placeholder="jdbc-url/database..."
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Username">
                        <Input
                          onChange={({ detail }) => setSourceDBuser(detail.value)}
                          value={sourceDBuser}
                          placeholder="database username"
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Password">
                        <Input
                          onChange={({ detail }) => setSourceDBpass(detail.value)}
                          value={sourceDBpass}
                          placeholder="database password..."
                          type="password"
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="DB Table Name">
                        <Input
                          onChange={({ detail }) => setSourceDBtable(detail.value)}
                          value={sourceDBtable}
                          placeholder="database table name..."
                        />
                      </FormField>
                    </FormField>
                  )}
                </FormField>
              </FormField>
            )}
          </FormField>
        </Container>

        {[...Array(numberOfFields)].map((e, i) => (
          <Container key={i} header={<Header variant="h2">Field Configuration</Header>}>
            <FormField stretch={true}>
              <FormField
                stretch={true}
                label="Field Name"
                description="Enter the exact name of the field you wish to tokenize"
              >
                <Input
                  // onChange={({ detail }) => setFieldValue(detail.value)}
                  // value={fieldValue}
                  placeholder="Enter field name"
                />
              </FormField>
              <div style={{ marginTop: '10px' }} />
              <ColumnLayout columns={2}>
                <div>
                  <FormField
                    label="Select Tokenization Method"
                    description="There are multiple tokenization methods, select the most appropriate for your use case. Select info for additional information."
                  >
                    <Select
                      selectedOption={tokenMethod}
                      onChange={({ detail }) => setTokenMethod(detail.selectedOption)}
                      options={[
                        {
                          label: 'Deterministic Encryption',
                          value: 'deterministic'
                        },
                        {
                          label: 'Format-preserving Encryption',
                          value: 'fpe'
                        },
                        {
                          label: 'Hashing',
                          value: 'hash'
                        }
                      ]}
                      placeholder="Deterministic Encryption"
                      filteringType="auto"
                    />
                  </FormField>
                  <div style={{ marginTop: '10px' }} />
                  {tokenMethod.value == 'fpe' && (
                    <FormField
                      label="FPE Alphabet Type"
                      description="Select the type of alphabet to use for the format-preserving operation. The length of the out will be determined by the source data."
                    >
                      <Select
                        // selectedOption={fpeAlphabet}
                        // onChange={({ detail }) => setFpeAlphabet(detail.selectedOption)}
                        options={[
                          {
                            label: 'Numeric',
                            value: 'Numeric'
                          },
                          {
                            label: 'Hexadecimal',
                            value: 'Hexadecimal'
                          },
                          {
                            label: 'Uppercase Alphanumeric',
                            value: 'UpperCaseAlphaNumeric'
                          },
                          {
                            label: 'Alphanumeric',
                            value: 'AlphaNumeric'
                          },
                          {
                            label: 'Printable ASCII',
                            value: 'PrintableASCII'
                          }
                        ]}
                        placeholder="Numeric"
                        filteringType="auto"
                      />
                    </FormField>
                  )}
                </div>
                <FormField
                  label="Wrapped Tokenization Key"
                  description="Paste your existing tokenization key below, or click generate key to have one generated for you."
                >
                  <Alert type="warning">
                    <b>Note:</b> If you choose a reversible tokenization operation, you need to persist this
                    tokenization key. We recommend AWS Secrets Manager as a storage mechanism.
                  </Alert>
                  <div style={{ marginTop: '10px' }} />
                  <Textarea
                    // onChange={({ detail }) => setKeyValue(detail.value)}
                    value={keyValue}
                    placeholder="Enter your wrapped encryption key"
                  />
                  <div style={{ marginTop: '10px' }} />
                  <Button
                    onClick={async () => {
                      const request = await generateWrappedKey();
                      const response = await request.json();
                      console.log(response);
                      setKeyValue(response.wrappedKey);
                    }}
                  >
                    Generate Key
                  </Button>
                </FormField>
              </ColumnLayout>
            </FormField>
          </Container>
        ))}

        {/* Target Details */}
        <Container header={<Header variant="h2">Target Data Schema</Header>}>
          <FormField stretch={true}>
            <Tiles
              onChange={({ detail }) => setTargetDataType(detail.value)}
              value={targetDataType}
              items={TARGET_TYPE}
            />
          </FormField>
          <div style={{ marginTop: '10px' }} />
          <FormField stretch={true}>
            {targetDataType == 'glue' ? (
              <ColumnLayout columns={3}>
                <FormField label="Database" description="Select the database within AWS Glue where your schema resides">
                  <Select
                    selectedOption={targetGlueDBValue}
                    onChange={({ detail }) => {
                      setTargetGlueDBValue(detail.selectedOption);
                      props.getGlueTables('target', detail.selectedOption.value);
                    }}
                    options={props.targetGlueDBValues}
                    placeholder="AWS glue database name"
                    filteringType="auto"
                  />
                </FormField>
                <FormField
                  label="Table"
                  description="Select the table within AWS Glue where your schema has been defined"
                >
                  <Select
                    selectedOption={targetGlueTable}
                    onChange={({ detail }) => setTargetGlueTable(detail.selectedOption)}
                    options={props.targetGlueTables}
                    placeholder="AWS Glue table name"
                    filteringType="auto"
                  />
                </FormField>
                <div className="custom-header">
                  <Button
                    href="https://console.aws.amazon.com/glue/home?region=us-east-1#addCrawler:"
                    iconAlign="right"
                    iconName="external"
                  >
                    Create Schema
                  </Button>
                </div>
              </ColumnLayout>
            ) : (
              <FormField stretch={true}>
                <FormField
                  label="Connection Type"
                  description="Select the type of connection for your source data store"
                >
                  <Select
                    selectedOption={targetConnectionType}
                    onChange={({ detail }) => setTargetConnectionType(detail.selectedOption)}
                    options={[
                      { label: 's3', value: 's3' },
                      { label: 'mysql', value: 'mysql' },
                      { label: 'postgresql', value: 'postgresql' },
                      { label: 'redshift', value: 'redshift' },
                      { label: 'sqlserver', value: 'sqlserver' },
                      { label: 'oracle', value: 'oracle' }
                    ]}
                  />
                </FormField>
                <div style={{ marginTop: '10px' }} />
                <FormField stretch={true}>
                  {targetConnectionType.value == 's3' && (
                    <FormField
                      label="S3 Connection Options"
                      description="Configure the below options if your connection type is from Amazon s3"
                    >
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="S3 Path" description="The path to your data source inside Amazon S3">
                        <Input
                          onChange={({ detail }) => setTargetS3Path(detail.value)}
                          value={targetS3Path}
                          placeholder=""
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Data Format">
                        <Select
                          selectedOption={targetS3Format}
                          onChange={({ detail }) => setTargetS3Format(detail.selectedOption)}
                          options={[
                            { label: 'avro', value: 'avro' },
                            { label: 'csv', value: 'csv' },
                            { label: 'json', value: 'json' },
                            { label: 'orc', value: 'orc' },
                            { label: 'parquet', value: 'parquet' },
                            { label: 'glueparquet', value: 'glueparquet' }
                          ]}
                        />
                      </FormField>
                    </FormField>
                  )}
                  {targetConnectionType.value !== 's3' && (
                    <FormField
                      label="Database Connection Options"
                      description="Configure the below options if your connection type is a relational database or Amazon Redshift"
                    >
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="URL (in JDBC format)">
                        <Input
                          onChange={({ detail }) => setTargetDBURL(detail.value)}
                          value={targetDBURL}
                          placeholder="jdbc-url/database..."
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Username">
                        <Input
                          onChange={({ detail }) => setTargetDBuser(detail.value)}
                          value={targetDBuser}
                          placeholder="database username"
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="Password">
                        <Input
                          onChange={({ detail }) => setTargetDBpass(detail.value)}
                          value={targetDBpass}
                          placeholder="database password..."
                          type="password"
                        />
                      </FormField>
                      <div style={{ marginTop: '10px' }} />
                      <FormField label="DB Table Name">
                        <Input
                          onChange={({ detail }) => setTargetDBtable(detail.value)}
                          value={targetDBtable}
                          placeholder="database table name..."
                        />
                      </FormField>
                    </FormField>
                  )}
                </FormField>
              </FormField>
            )}
          </FormField>
        </Container>
      </SpaceBetween>
    </Form>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Cloak',
        href: '#/'
      },
      {
        text: 'Create Tokenization Job',
        href: '#/table'
      }
    ]}
  />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <HelpPanel
    header={<h2>Amazon Cloak</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="https://amazon.com">Service Documentation</a>
          </li>
        </ul>
      </div>
    }
  >
    <p>Here goes a description of Amazon Cloak.</p>
  </HelpPanel>
];
