import React from 'react';
import ServiceNavigation from '../../ServiceNavigation.jsx';
import {
  AppLayout,
  Button,
  ColumnLayout,
  Icon,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link
} from '../../../aws-ui-components';

import './servicehomepage.scss';

export default () => {
  return (
    <AppLayout
      disableContentPaddings={true}
      navigation={<ServiceNavigation />}
      content={<Content />}
      contentType="default"
      navigationOpen={false}
      toolsHide={true}
    />
  );
};

const Content = () => (
  <Box padding="s" margin={{ bottom: 'l' }}>
    <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
      <Box padding={{ vertical: 'xxl' }}>
        <Grid
          gridDefinition={[
            { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          <Box fontWeight="light" padding={{ top: 'xs' }}>
            <span className="custom-home__category">Security &amp; Compliance</span>
          </Box>

          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              Amazon Cloak
            </Box>
            <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Secure tokenization of your sensitive data
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">
                Amazon Cloak is a data tokenization service that simplifies the complex heavy lifting of a standard
                tokenization solution. Access, store, and analyze your data from anywhere.
              </span>
            </Box>
          </div>

          <Container>
            <SpaceBetween size="xl">
              <Box variant="h2" padding="n">
                Create Cloak Tokenization Job
              </Box>
              <Button href="#/create" variant="primary">
                Create Job
              </Button>
            </SpaceBetween>
          </Container>
        </Grid>
      </Box>
    </Grid>

    <Box padding={{ top: 'xxl' }}>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
          { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
        ]}
      >
        <div>
          <SpaceBetween size="l">
            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Benefits and features
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Cloak console
                    </Box>
                    <Box variant="p">
                      Create, monitor, and manage your data de-identification with a few simple clicks from the Cloak
                      console.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Pay as you go Pricing
                    </Box>
                    <Box variant="p">
                      Pay only for the amount of data that is processed by Amazon Cloak and eliminate spending on idle
                      resources.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Flexible Configuration
                    </Box>
                    <Box variant="p">
                      Take advantage of automated schema definition through AWS Glue, or choose to manually define
                      connection parameters from any AWS Glue supported data store.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Tools and libraries
                    </Box>
                    <Box variant="p">
                      Take advantage of a variety of tools and libraries for managing your tokenization jobs, like the
                      Cloak API, the AWS Command Line Interface (AWS CLI), and the AWS SDKs.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Use cases
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Data Pipeline Integration
                    </Box>
                    <Box variant="p">
                      Integrate the Cloak API into your data pipeline by leveraging pre-defined schemas within AWS Glue
                      or by defining your own connection parameters.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Automated de-identification
                    </Box>
                    <Box variant="p">
                      Leverage Amazon Macie and the Cloak API to automatically discover and de-identify sensitive
                      information within your S3 buckets.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            <Container header={<Header variant="h2">Related services</Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" href="https://aws.amazon.com/glue/" external>
                      AWS Glue
                    </Link>
                  </Box>
                  <Box variant="p">Use AWS Glue to run Extract, Transform, and Load (ETL) pipelines.</Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" href="https://aws.amazon.com/macie/" external>
                      Amazon Macie
                    </Link>
                  </Box>
                  <Box variant="p">
                    Use Amazon Macie to automate the discovery of sensitive information through machine learning.
                  </Box>
                </div>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        </div>

        <div className="custom-home__sidebar">
          <SpaceBetween size="xxl">
            <Container
              header={
                <Header variant="h2">
                  Getting started <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="https://w.amazon.com/bin/view/AmazonCloak/" target="_blank">
                    Amazon Cloak Wiki
                  </Link>
                </li>
              </ul>
            </Container>
            <Container
              header={
                <Header variant="h2">
                  More resources <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="https://quip-amazon.com/w0wWAqbcIije/PR-FAQ" target="_blank">
                    PRFAQ
                  </Link>
                </li>
                <li>
                  <Link href="/CloakProposalFinal.pdf">Service Proposal</Link>
                </li>
                <li>
                  <Link href="https://amzn-aws.slack.com/archives/C028Q2NBJSJ">Data Masking Slack Channel</Link>
                </li>
              </ul>
            </Container>
          </SpaceBetween>
        </div>
      </Grid>
    </Box>
  </Box>
);
