export const CLOAK_OPERATION = [
  {
    label: 'De-identify',
    value: 'deidentify',
    description: 'Tokenize your plaintext data.'
  },
  {
    label: 'Re-identify',
    value: 'reidentify',
    description: 'Re-identify tokenized data within existing data sources.'
  }
];

export const SOURCE_TYPE = [
  {
    label: 'AWS Glue Table',
    value: 'glue',
    description: 'Choose an AWS Glue Database and Table.'
  },
  {
    label: 'Manually Configure',
    value: 'options',
    description: 'Manually configure a supported data store'
  }
];

export const TARGET_TYPE = [
  {
    label: 'AWS Glue Table',
    value: 'glue',
    description: 'Choose an AWS Glue Database and Table.'
  },
  {
    label: 'Manually Configure',
    value: 'options',
    description: 'Manually configure a supported data store'
  }
];
